import { useRouter } from "next/router";
import React from "react";
import EpicPlayText from "/public/svgs/epicplay.svg";
import Logo from "/public/svgs/logo.svg";
import EPButton from "components/UI/EPButton";
import Text from "components/Brand/Text";
import Link from "next/link";
import {
  FAVORITES_ROUTE,
  HOME_ROUTE,
  LEADERBOARD_ROUTE,
  PROFILE_ROUTE,
} from "constants/routes.const";
import LoginUserButton from "components/LoginUserButton";
import clsx from "clsx";

const BottomAppBar = () => {
  const router = useRouter();
  return (
    <div className="z-2 fixed inset-0 top-auto flex min-h-[62px] items-center justify-center bg-black   md:hidden">
      <div className="grid grid-cols-5">
        <RouteIconLink route={HOME_ROUTE} />
        <RouteIconLink route={LEADERBOARD_ROUTE} />
        {/* logo */}
        <div className="mx-auto flex  -translate-y-3 items-center justify-center  ">
          <Link title="EpicPlay" href="/">
            <span className="mx-auto flex h-12 w-12  items-center justify-center rounded-full bg-[#87cdff] p-2 shadow-[0px_0px_0px_6px_rgba(0,142,241,0.42)]">
              <Logo alt="logo" height="30px" width="30px" />
            </span>
          </Link>
        </div>
        <RouteIconLink route={FAVORITES_ROUTE} />
        <LoginUserButton mobile />
      </div>
    </div>
  );
};
export default BottomAppBar;

function RouteIconLink({ route }) {
  const router = useRouter();
  const Icon =
    router.pathname.split("?")[0] == route.path
      ? route.ActiveIcon
      : route.InactiveIcon;
  const isActive = router.pathname.split("?")[0] == route.path;
  return (
    <EPButton
      href={route.path}
      prefetch={false}
      variant="ghost"
      className={clsx(
        "flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs",
        isActive && "!text-primary-content/100"
      )}
    >
      <Icon height={20} width={20} size={20} />
      {route.name}
    </EPButton>
  );
}
