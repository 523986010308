import LoginSVG from "/public/svgs/login.svg";
import HomeInactive from "/public/svgs/home-inactive.svg";
import HomeActive from "/public/svgs/home-active.svg";
import LeaderBoardInactive from "/public/svgs/leaderboard-inactive.svg";
import LeaderBoardActive from "/public/svgs/leaderboard-active.svg";
import FavoritesActive from "/public/svgs/favorites-active.svg";
import FavoritesInactive from "/public/svgs/favorites-inactive.svg";
export const HOME_ROUTE = {
  path: "/",
  name: "Home",
  ActiveIcon: HomeActive,
  InactiveIcon: HomeInactive,
};
export const LEADERBOARD_ROUTE = {
  path: "/leaderboard",
  name: "Leaderboard",
  ActiveIcon: LeaderBoardActive,
  InactiveIcon: LeaderBoardInactive,
};
export const FAVORITES_ROUTE = {
  path: "/favorites",
  name: "Favorites",
  ActiveIcon: FavoritesActive,
  InactiveIcon: FavoritesInactive,
};
export const PROFILE_ROUTE = {
  path: "/profile",
  name: "Profile",
  ActiveIcon: LoginSVG,
  InactiveIcon: LoginSVG,
};

// get path functions
export const getGamePath = pathname => {
  return `/games/${pathname}`;
};

export const getPlayPath = pathname => {
  return `/games/${pathname}/play`;
};

export const getCategoryPath = pathname => {
  return `/category/${pathname}`;
};
