import { useRouter } from "next/router";
import React from "react";
import EpicPlayLogo from "public/epicplay-logo.png";
import {
  FaDiscord,
  FaFacebookSquare,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import classes from "./styles.module.scss";
import Content from "../Content";
import Image from "next/image";
import { cn } from "utils/styling";

const Footer = ({ noBg = false }) => {
  return (
    // logo and name on left
    // links on right
    <footer
      className={cn("bg-tertiary-base pb-8 md:pb-0", noBg && "bg-transparent")}
    >
      <div className={classes.footer}>
        <Content>
          <div className={classes.container}>
            <div className={classes.website}>
              <a className={classes.brand} title="EpicPlay" href="/">
                <div className={classes.logo}>
                  <Image src={EpicPlayLogo} alt="epicplaylogo" width={163} />
                </div>
              </a>
              <p className={classes.copyright}>Play anywhere, anytime.</p>
            </div>
            <div className={classes.links}>
              {/* 3 groups */}
              <div className={classes.linkGroup}>
                <span className={classes.title}>Legal</span>
                <FooterLink
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener"
                >
                  Privacy Policy
                </FooterLink>
                <FooterLink
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener"
                >
                  Terms and Conditions
                </FooterLink>
              </div>
              <div className={classes.linkGroup}>
                <span className={classes.title}>For Gamers</span>
                <FooterLink href="/about-us">
                  About Us
                </FooterLink>
                <FooterLink href="/blogs">Blogs</FooterLink>
                <FooterLink
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf2joFmtQTIep55TEais5dkP3rl5u_giQjx5SOQ-Nffv0sTZg/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Request Games
                </FooterLink>
                <FooterLink
                  href="https://docs.google.com/forms/d/e/1FAIpQLSenUCfyLzBi42Xgn4Fsl5nSeBIV6cmZ8Eg7HPuwZ8H1izUQBw/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report Issues
                </FooterLink>
              </div>
              <div className={classes.linkGroup}>
                <span className={classes.title}>Let&apos;s Connect</span>
                <div className={classes.footerIconGrid}>
                  <FooterLink
                    href="https://discord.com/invite/pntAKRxaeG"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Discord"
                  >
                    <FaDiscord />
                  </FooterLink>
                  <FooterLink
                    href="https://www.facebook.com/Epic-Play-100534342864317"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FaFacebookSquare />
                  </FooterLink>
                  <FooterLink
                    href="https://www.instagram.com/epicplay.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <AiFillInstagram />
                  </FooterLink>
                  {/* email */}
                  <FooterLink
                    href="mailto:epicplayofficial.in@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Email"
                  >
                    <MdEmail />
                  </FooterLink>
                  <FooterLink
                    href="https://youtube.com/@epicplay_in"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Youtube"
                  >
                    <FaYoutube />
                  </FooterLink>
                  <FooterLink
                    href="https://twitter.com/epicplay_in"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </FooterLink>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </footer>
  );
};
export default Footer;

function FooterLink({ href, children, ...props }) {
  return (
    <a href={href} className={classes.link} {...props}>
      {children}
    </a>
  );
}
