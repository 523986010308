import React from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";

/**
 * @typedef {'large' | 'normal' | 'small'} Variant
 * @description The available variants for the Text component.
 *
 * @typedef {'primary' | 'secondary' | 'tertiary'} Color
 * @description The available colors for the Text component.
 *
 * @typedef {'regular' | 'medium' | 'semibold' | 'bold'} fontWeight
 * @description The available font weights for the Text component.
 *
 * @typedef {'p | span'} Tag
 * @description The available HTML tags for the Text component.
 *
 * @typedef {true | false} justify
 * @description The available justify options for the Text component.
 *
 */

/**
 * Renders a Text Tag with a given variant and children.
 *
 * @param {Object} props - The component props.
 * @param {Variant} props.variant - The variant of the button component.
 * @param {fontWeight} props.fontWeight - The font weight of the text component.
 * @param {React.ReactNode} props.children - The children to render inside the button component.
 * @param {string} [props.as] - The HTML tag to render.
 * @param {boolean} [props.justify] - Whether the text component should be justified.
 * @param {string} [props.className] - The CSS class name to apply to the button component.
 * @param {Color} [props.color] - The color of the Text component.
 * @returns {React.ReactElement} The rendered button or link component.
 */
function Text({
  as = "p",
  variant = "normal",
  color = "secondary",
  fontWeight = "regular",
  justify = false,
  className,
  children,
}) {
  const Tag = as;
  const listItem = as === "li";
  const colorClassName = classes[color];
  const variantClassName = classes[variant];
  const fontWeightClassName = classes[fontWeight];
  const combinedClassNames = clsx(
    colorClassName,
    variantClassName,
    fontWeightClassName,
    justify && classes.justify,
    listItem && classes.listItem,
    className
  );
  return <Tag className={combinedClassNames}>{children}</Tag>;
}

export default Text;
