import AvatarOne from "public/icons/avatar-one.png";
import AvatarTwo from "public/icons/avatar-two.png";
import AvatarThree from "public/icons/avatar-three.png";
import AvatarFour from "public/icons/avatar-four.png";
import Image from "next/image";

export const PlayerAvatar = ({ username, size = 40 }) => {
  const index = username?.charCodeAt(0) % 4 || 5;

  switch (index) {
    case 1:
      return <AvatarImage src={AvatarOne} size={size} />;
    case 2:
      return <AvatarImage src={AvatarTwo} size={size} />;
    case 3:
      return <AvatarImage src={AvatarThree} size={size} />;
    case 4:
      return <AvatarImage src={AvatarFour} size={size} />;
    default:
      return <AvatarImage src={AvatarThree} size={size} />;
  }
};

function AvatarImage({ src, size }) {
  return <Image src={src} alt="avatar" height={size} width={size} />;
}
