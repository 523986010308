import clsx from "clsx";
import React from "react";
function Content({ children, ...rest }) {
  const { className } = rest;
  const combinedClassName = clsx("pt-0 pb-4 px-3 md:px-8 lg:px-10", className);
  return (
    <div className={combinedClassName} {...rest}>
      {children}
    </div>
  );
}

export default Content;
