import EPButton from "components/UI/EPButton";

import LoginSVG from "/public/svgs/login.svg";
import { FcGoogle } from "react-icons/fc";
import React, { Suspense, use, useEffect } from "react";
import useAuthContext from "src/contexts/AuthContext";
import { PROFILE_ROUTE } from "constants/routes.const";
import { useRouter } from "next/router";
import clsx from "clsx";
import { PlayerAvatar } from "components/LeaderBoard/PlayerAvatar";
import { ImSpinner10 } from "react-icons/im";

function LoginUserButton({ mobile = false }) {
  if (mobile) return <LoginUserMobile />;
  return <LoginUser />;
}

export default LoginUserButton;

function LoginUser() {
  const { authUser, handleProviderLogin } = useAuthContext();
  const route = PROFILE_ROUTE;
  const router = useRouter();
  const isActive = router.pathname.split("?")[0] == route.path;
  if (!authUser)
    return (
      <EPButton
        variant="ghost"
        disabled
        className="h-full items-center justify-center  gap-2"
      >
        <ImSpinner10 className="mx-[26px] animate-spin text-lg" />
      </EPButton>
    );

  if (authUser && authUser?.anonymous)
    return (
      <EPButton
        variant="ghost"
        onClick={async () => {
          handleProviderLogin();
        }}
        className="flex min-w-fit items-center justify-center gap-2"
      >
        {/* for now only google */}
        <FcGoogle size={20} /> <span>Login</span>
      </EPButton>
    );

  return (
    <EPButton
      variant="ghost"
      className={clsx(
        "flex min-w-fit items-center justify-center gap-2",
        isActive && "!text-primary-content/100"
      )}
      href={PROFILE_ROUTE.path}
    >
      <PlayerAvatar username={authUser.name} size={22} />
      {authUser.name}
    </EPButton>
  );
}
function LoginUserMobile() {
  const route = PROFILE_ROUTE;
  const Icon = route.ActiveIcon;

  const router = useRouter();
  const isActive = router.pathname.split("?")[0] == route.path;
  const { authUser, onRequestAuth, handleProviderLogin } = useAuthContext();
  if (!authUser)
    return (
      <EPButton
        disabled
        variant="ghost"
        className="flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs"
      >
        <ImSpinner10 className=" animate-spin text-lg" />
      </EPButton>
    );
  if (authUser && authUser?.anonymous)
    return (
      <EPButton
        onClick={async () => {
          handleProviderLogin();
        }}
        variant="ghost"
        className="flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs"
      >
        {/* for now only google */}
        <FcGoogle size={20} />
        Login
      </EPButton>
    );

  return (
    <EPButton
      href={PROFILE_ROUTE.path}
      variant="ghost"
      className={clsx(
        "flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs",
        isActive && "!text-primary-content/100"
      )}
    >
      <span
        className={clsx(
          "flex items-center justify-center  rounded-full p-1",
          isActive && "bg-primary/80"
        )}
      >
        <PlayerAvatar username={authUser.name} size={18} />
      </span>
      {route.name}
    </EPButton>
  );
}
