import { useRouter } from "next/router";
import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Head from "next/head";
import Script from "next/script";
import BottomAppBar from "./BottomAppBar";
import { AuthContextProvider } from "src/contexts/AuthContext";
import ClientInfoProvider from "src/contexts/ClientInfoContext";
import HideOnWWW from "components/HideOnWWW";
import { CLIENT_ID } from "constants/index";
import HideOnMarketingSubDomains from "components/HideOnMarketingSubDomains";

const Layout = ({
  children,
  noNavbar,
  noFooter,
  noBottomAppBar,
  noFooterBg = false,
}) => {
  return (
    <>
      <AuthContextProvider>
        <ClientInfoProvider>
          <Head key="core-scripts">
            <HideOnWWW>
              <script
                async
                src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${CLIENT_ID}`}
                // data-page-url="www.epicplay.in"
                crossorigin="anonymous"
                //  handle adblocker
                onError={() => {
                  window.cantShowAds = true;
                }}
              ></script>
            </HideOnWWW>
          </Head>
          {/* <HideOnMarketingSubDomains>
            <Script id="adreanalead-script" strategy="lazyOnload">
              {`var _nAdzq=_nAdzq||[];(function(){
              _nAdzq.push(["setIds","f60d80a4f00cd631"]);
              var e="https://notifpush.com/scripts/";
              var t=document.createElement("script");
              t.type="text/javascript";
              t.defer=true;
              t.async=true;
              t.src=e+"nadz-sdk.js";
              var s=document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(t,s)})();`}
            </Script>
          </HideOnMarketingSubDomains> */}

          <div className="flex h-screen flex-col">
            {!noNavbar && <Navbar />}

            <main className="relative w-full max-w-[var(--max-width)] flex-1 self-center pb-4">
              {children}
            </main>

            {!noFooter && <Footer noBg={noFooterBg} />}
            {!noBottomAppBar && <BottomAppBar />}
          </div>
        </ClientInfoProvider>
      </AuthContextProvider>
    </>
  );
};
export default Layout;
