import { useAdBlocker } from "hooks/useAdBlocker";
import { useIsBot } from "hooks/useIsBot";
import { useIsOffline } from "hooks/useIsOffline";
import dynamic from "next/dynamic";
import React, { createContext, useContext, useEffect, useState } from "react";

export const ClientInfoContext = createContext(null);

export function useClientInfo() {
  return useContext(ClientInfoContext);
}

const AdBlockerModalComponent = dynamic(() =>
  import("components/UI/AdBlockerModal")
);
export default function ClientInfoProvider({ children }) {
  const isBot = useIsBot();
  const isOffline = useIsOffline();
  const isAdBlocked = useAdBlocker();
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    if (isBot === null || isOffline === null || isAdBlocked === null) return;
    setShowAds(!isOffline && !isBot && isAdBlocked === false);
  }, [isBot, isOffline, isAdBlocked]);
  const value = {
    isBot,
    isOffline,
    isAdBlocked,
    showAds,
  };
  return (
    <ClientInfoContext.Provider value={value}>
      <>
        {children}
        {isAdBlocked && <AdBlockerModalComponent />}
      </>
    </ClientInfoContext.Provider>
  );
}
