import { useRouter } from "next/router";
import React from "react";
import EpicPlayLogo from "/public/svgs/epicplay-logo.svg";
import EPButton from "components/UI/EPButton";
import LoginUserButton from "components/LoginUserButton";
import { FAVORITES_ROUTE, LEADERBOARD_ROUTE } from "constants/routes.const";
import clsx from "clsx";
import Link from "next/link";
const Navbar = () => {
  const router = useRouter();
  return (
    <div className="bg-tertiary-base">
      <div className="mx-auto flex max-w-[var(--max-width)]  justify-between gap-4 px-6  py-2 md:px-8 lg:px-10">
        <div className="flex items-center justify-start">
          <Link title="EpicPlay" href="/">
            <EpicPlayLogo height="100%" width="140px" alt="EpicPlay" />
          </Link>
        </div>
        <div className="  hidden items-center justify-end gap-1 md:flex">
          <RouteLink route={LEADERBOARD_ROUTE} />
          <RouteLink route={FAVORITES_ROUTE} />
          <LoginUserButton />
        </div>
      </div>
    </div>
  );
};
export default Navbar;

function RouteLink({ route }) {
  const router = useRouter();
  const isActive = router.pathname.split("?")[0] == route.path;
  const Icon = isActive ? route.ActiveIcon : route.InactiveIcon;
  return (
    <EPButton
      href={route.path}
      prefetch={false}
      variant="ghost"
      className={clsx(
        "flex w-full items-center justify-center gap-2",
        isActive && "!text-primary-content/100"
      )}
    >
      <Icon height={20} width={20} />
      {route.name}
    </EPButton>
  );
}
