import { useState, useEffect } from "react";
import { useIsOffline } from "./useIsOffline";

export function useAdBlocker() {
  const isOffline = useIsOffline();
  const [isAdBlocked, setIsAdBlocked] = useState(null);
  useEffect(() => {
    if (isOffline === null || isOffline) return;
    if (window.cantShowAds === true) setIsAdBlocked(true);
    else setIsAdBlocked(false);
  }, [isOffline]);

  return !isOffline && isAdBlocked;
}
