import { useState, useEffect } from "react";

export function useIsOffline() {
  const [isOffline, setIsOffline] = useState(null);
  useEffect(() => {
    const isOffline = typeof navigator !== "undefined" && !navigator.onLine;
    setIsOffline(isOffline);
  }, []);
  return isOffline;
}
